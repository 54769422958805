import { useNode } from '@craftjs/core';
import { motion, useMotionValue } from 'framer-motion';
import { ComponentProps, forwardRef, useCallback, useEffect, useRef } from 'react';

import { TimelineSectionSettings } from '../wrappers/TimelineSectionSettings';
import { ImageLazy } from 'components';
import { fadeInProps } from 'containers/CustomLandingPage/animations';
import { getMaxScrollWidth } from 'containers/CustomLandingPage/components/NewCarouselSection';
import { useAutoScroll } from 'containers/CustomLandingPage/components/NewCarouselSection';
import { NewDesktopSection } from 'containers/CustomLandingPage/components/NewDesktopSection';
import { useMediaQueries } from 'hooks/useMediaQueries';

export const DEFAULT_TIMELINE_ITEMS = Array(5).fill({
  mainPhotoSrc: 'https://cdn.thenold.com/images/2024-12-10_11-31-34__Leonie_compressed.webp',
  mainPhotoBlur: 'https://cdn.thenold.com/images/2024-12-10_11-31-34__Leonie_compressed.webp',
  secondaryPhotoSrc: 'https://cdn.thenold.com/images/2024-12-10_11-31-34__Leonie_compressed.webp',
  secondaryPhotoBlur: 'https://cdn.thenold.com/images/2024-12-10_11-31-34__Leonie_compressed.webp',
  title: 'Paris, Feb 2023',
  description:
    'Such a beautiful day in Paris spent with my family. The shoes are such a showstopper.',
});

interface TimelineItem {
  mainPhotoSrc: string;
  mainPhotoBlur: string;
  secondaryPhotoSrc: string;
  secondaryPhotoBlur: string;
  title: string;
  description: string;
}

interface TimelineSectionProps {
  heading: string;
  items: TimelineItem[];
}

const TimelineSection = ({ heading, items }: TimelineSectionProps) => {
  const {
    connectors: { connect, drag },
  } = useNode();
  const isMobile = !useMediaQueries({ viewport: 'small' });

  return isMobile ? (
    <TimelineSectionMobile heading={heading} items={items} ref={ref => ref && connect(drag(ref))} />
  ) : (
    <NewDesktopSection heading={heading} ref={ref => ref && connect(drag(ref))}>
      <TimelineSectionItems items={items} />
    </NewDesktopSection>
  );
};

const TimelineSectionMobile = forwardRef(
  (
    { heading, items }: { heading: string; items: TimelineItem[] },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const x = useMotionValue(0);
    const containerRef = useRef<HTMLDivElement>(null);
    const constraintsRef = useRef({ left: 0, right: 0 });

    const { pauseAutoScroll } = useAutoScroll(x, containerRef);

    // Update drag constraints when the component mounts and when the window resizes
    useEffect(() => {
      const updateConstraints = () => {
        if (containerRef.current) {
          const maxScrollWidth = getMaxScrollWidth(containerRef);
          constraintsRef.current = { left: -maxScrollWidth, right: 0 };
        }
      };

      updateConstraints();

      window.addEventListener('resize', updateConstraints);

      return () => {
        window.removeEventListener('resize', updateConstraints);
      };
    }, []);

    return (
      <motion.div ref={ref} className="py-5 w-full font-syne uppercase font-bold" {...fadeInProps}>
        <div className="flex flex-col gap-1 px-2 py-2">
          <h2 className="text-2 text-center">{heading} </h2>
        </div>
        <motion.div
          ref={containerRef}
          className="flex w-full cursor-grab"
          drag="x"
          dragConstraints={constraintsRef.current}
          dragElastic={0.5}
          dragTransition={{ timeConstant: 200, power: 0.5 }}
          style={{ x }}
          onDragStart={pauseAutoScroll}
        >
          <TimelineSectionItems items={items} />
        </motion.div>
      </motion.div>
    );
  }
);

const TimelineSectionItems = ({ items = DEFAULT_TIMELINE_ITEMS }: { items: TimelineItem[] }) => {
  const ImageComponent = useCallback(
    ({
      blurSrc,
      ...rest
    }: Omit<ComponentProps<typeof ImageLazy>, 'blurSrc'> & { blurSrc?: string }) => {
      return blurSrc ? (
        <ImageLazy {...rest} blurSrc={blurSrc} objectFit="contain" />
      ) : (
        <img {...rest} />
      );
    },
    []
  );

  return (
    <>
      {items.map((item, index) => (
        <div key={index} className="py-6 grid align-center justify-items-center">
          <ImageComponent
            draggable={false}
            src={item.mainPhotoSrc}
            blurSrc={item.mainPhotoBlur}
            alt={item.title}
            className="aspect-[3/4] w-[200px] max-w-[80vw] object-contain mx-4 rounded-3xl"
          />
          <div className="relative w-full h-[0px] border-t border-black my-4">
            <div className="absolute w-[12px] h-[12px] bg-black rounded-full left-1/2 -translate-x-1/2 -translate-y-1/2" />
          </div>
          <ImageComponent
            draggable={false}
            src={item.secondaryPhotoSrc}
            blurSrc={item.secondaryPhotoBlur}
            alt={item.title}
            className="aspect-[3/4] w-[160px] max-w-[70vw] object-contain rounded-2xl"
          />
          <h2 className="px-2 text-1 font-syne font-bold mt-2">{item.title}</h2>
          <p className="px-2 text-00 font-montserrat mt-1">{item.description}</p>
        </div>
      ))}
    </>
  );
};

TimelineSection.craft = {
  displayName: 'Timeline Section',
  props: {
    heading: 'Once upon a time...',
    items: DEFAULT_TIMELINE_ITEMS,
  },
  related: {
    settings: TimelineSectionSettings,
  },
};

export default TimelineSection;
