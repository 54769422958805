import { useNode } from '@craftjs/core';

import { useGetConfiguration } from 'hooks/api/useGetConfiguration';

export const CarouselSectionSettings = () => {
  const {
    actions: { setProp },
    title,
    subtitle,
    collectionId,
    items,
  } = useNode(node => ({
    title: node.data.props.title,
    subtitle: node.data.props.subtitle,
    collectionId: node.data.props.collectionId,
    items: node.data.props.items,
  }));

  const { data: configData } = useGetConfiguration();
  const collections = configData?.collections || [];

  return (
    <div className="flex flex-col gap-2">
      <div>Carousel Section Settings</div>

      <label>
        Title
        <input
          type="text"
          value={title}
          onChange={e => setProp((props: any) => (props.title = e.target.value))}
          className="w-full p-1"
        />
      </label>

      <label>
        Subtitle
        <input
          type="text"
          value={subtitle}
          onChange={e => setProp((props: any) => (props.subtitle = e.target.value))}
          className="w-full p-1"
        />
      </label>

      <label>
        Collection
        <select
          value={collectionId || ''}
          onChange={e =>
            setProp((props: any) => (props.collectionId = Number(e.target.value) || undefined))
          }
          className="w-full p-1"
        >
          <option value="">All Listings</option>
          {collections.map(collection => (
            <option key={collection.id} value={collection.id}>
              {collection.name}
            </option>
          ))}
        </select>
      </label>

      <div>
        <div className="font-bold mb-2">Items</div>
        {items.map((item: any, index: number) => (
          <div key={index} className="mb-4 p-2 border border-gray-200">
            <label>
              Image URL
              <input
                type="text"
                value={item.imageUrl}
                onChange={e =>
                  setProp((props: any) => {
                    props.items[index].imageUrl = e.target.value;
                  })
                }
                className="w-full p-1"
              />
            </label>
            <label>
              Blur Image URL
              <input
                type="text"
                value={item.imageBlurUrl}
                onChange={e =>
                  setProp((props: any) => {
                    props.items[index].imageBlurUrl = e.target.value;
                  })
                }
                className="w-full p-1"
              />
            </label>
            <label>
              Title
              <input
                type="text"
                value={item.title}
                onChange={e =>
                  setProp((props: any) => {
                    props.items[index].title = e.target.value;
                  })
                }
                className="w-full p-1"
              />
            </label>
            <label>
              Search Params
              <input
                type="text"
                value={item.searchParams}
                onChange={e =>
                  setProp((props: any) => {
                    props.items[index].searchParams = e.target.value;
                  })
                }
                className="w-full p-1"
              />
            </label>
            <div className="flex gap-2 mt-2">
              <button
                onClick={() =>
                  setProp((props: any) => {
                    if (index > 0) {
                      const newItems = [...props.items];
                      [newItems[index - 1], newItems[index]] = [
                        newItems[index],
                        newItems[index - 1],
                      ];
                      props.items = newItems;
                    }
                  })
                }
                disabled={index === 0}
                className="bg-blue-500 text-white px-2 py-1 rounded disabled:opacity-50"
              >
                ↑ Move Up
              </button>
              <button
                onClick={() =>
                  setProp((props: any) => {
                    if (index < props.items.length - 1) {
                      const newItems = [...props.items];
                      [newItems[index], newItems[index + 1]] = [
                        newItems[index + 1],
                        newItems[index],
                      ];
                      props.items = newItems;
                    }
                  })
                }
                disabled={index === items.length - 1}
                className="bg-blue-500 text-white px-2 py-1 rounded disabled:opacity-50"
              >
                ↓ Move Down
              </button>
              <button
                onClick={() =>
                  setProp((props: any) => {
                    props.items = props.items.filter((_: any, i: number) => i !== index);
                  })
                }
                className="bg-red-500 text-white px-2 py-1 rounded"
              >
                Remove Item
              </button>
            </div>
          </div>
        ))}
        <button
          onClick={() =>
            setProp((props: any) => {
              props.items = [
                ...props.items,
                {
                  imageUrl: '',
                  imageBlurUrl: '',
                  title: '',
                  searchParams: '',
                },
              ];
            })
          }
          className="bg-green-500 text-white px-2 py-1 rounded"
        >
          Add Item
        </button>
      </div>
    </div>
  );
};
